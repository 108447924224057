import { type ChangeEvent, useRef, useState } from 'react';

import { Box, TextField, type TextFieldProps, Typography } from '@mui/material';

import useCheckDevice from 'hooks/useCheckDevice';

1;
type TextAreaProps = TextFieldProps & {
  maxLength: number;
  value: string;
  helperText?: string;
  isUsingInModal?: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
};

// * rows를 가지고 높이를 조절하도록 하였습니다.
const MODAL_ROWS = 6.5;
const PC_ROWS = 7.5;
const MOBILE_ROWS = 4.2;

const StyledTextArea = ({
  maxLength,
  value,
  helperText,
  isUsingInModal = false,
  onChange,
  ...props
}: TextAreaProps) => {
  const { isMobile } = useCheckDevice();

  const textFieldRows = isMobile
    ? MOBILE_ROWS
    : isUsingInModal
      ? MODAL_ROWS
      : PC_ROWS;

  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const textAreaRef = useRef<HTMLDivElement>(null);

  const checkHasScrollBar = () => {
    if (textAreaRef.current === null) return;

    const textAreaEl = textAreaRef.current;
    if (textAreaEl.scrollHeight > textAreaEl.clientHeight) {
      setHasScrollBar(true);
    } else {
      setHasScrollBar(false);
    }
  };

  const checkMaxLengthError = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setIsError(value.length > maxLength);
  };

  const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    checkHasScrollBar();
    checkMaxLengthError(e);
    onChange(e);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '4px',
        borderColor: 'border.normal',
      }}
    >
      <TextField
        fullWidth
        multiline
        value={value}
        rows={textFieldRows}
        inputRef={textAreaRef}
        error={isError}
        InputProps={{
          sx: {
            padding: '8px 0px 0px 0px',
          },
        }}
        inputProps={{
          sx: {
            padding: '8px 5px 0px 16px',
            marginRight: hasScrollBar ? '5px' : '11px',
            marginBottom: '40px',

            fontSize: { xs: '14px', sm: '16px' },
            lineHeight: '1.7',

            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'gray.200',
              borderRadius: '6px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
          },
        }}
        onChange={handleChangeTextArea}
        {...props}
      />

      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: isError && helperText ? 'space-between' : 'flex-end',
          left: '16px',
          bottom: '0px',
          right: '16px',
          paddingTop: '8px',
          height: '40px',
          color: isError ? 'text.error' : 'gray.500',
        }}
      >
        {isError && helperText && (
          <Typography variant="body3">{helperText}</Typography>
        )}

        <Typography variant="body3">
          {`${value.length}/${maxLength}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default StyledTextArea;
