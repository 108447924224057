import { useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import { Typography } from '@mui/material';

import StyledTextArea from 'components/commons/StyleTextArea';
import CommonDialog from 'components/dialogs/CommonDialog';

const QuestionSection = ({
  joinQuestion,
  joinMembership,
  disabled,
}: {
  joinQuestion: string;
  joinMembership: (answer: string) => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation('channel');

  const [answer, setAnswer] = useState('');

  return (
    <>
      <CommonDialog.DialogContent>
        <Typography variant="h5Bold" sx={{ mb: 1 }}>
          {joinQuestion}
        </Typography>
        <StyledTextArea
          maxLength={200}
          value={answer}
          isUsingInModal
          onChange={(e) => {
            setAnswer(e.target.value);
          }}
        />
      </CommonDialog.DialogContent>
      <CommonDialog.DialogActions>
        <CommonDialog.Button
          onClick={() => joinMembership(answer)}
          buttonText={t('membership.apply')}
          variant="solid"
          disabled={answer.length === 0 || answer.length >= 200 || disabled}
        />
      </CommonDialog.DialogActions>
    </>
  );
};

export default QuestionSection;
