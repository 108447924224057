import { useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Avatar, Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { getChannelDetailData } from 'api/channel';
import useChannelMembershipApi from 'api/hooks/useChannelMembershipApi';

import ResponsiveTypography from 'components/commons/ResponsiveTypography';
import CommonDialog from 'components/dialogs/CommonDialog';

import useActiveAuth from 'hooks/useActiveAuth';
import useTanstackQuery from 'hooks/useTanstackQuery';

import type { ChannelDetailDataType } from 'types/channel';
import type { MembershipType } from 'types/channelMembership';

import convertQueryToString from 'utils/convertQueryToString';

import MembershipSelectSection from './MembershipSelectSection';
import QuestionSection from './QuestionSection';

const MembershipSignUpDialog = ({
  close: closeDialog,
  isOpen,
  membershipList,
  handle: initHandle = '',
}: {
  close: () => void;
  isOpen: boolean;
  membershipList: MembershipType[];
  handle?: string;
}) => {
  const { t } = useTranslation('channel');
  const {
    query: { handle = initHandle },
  } = useRouter();
  const { joinMembership } = useChannelMembershipApi();
  const { isAnonymousUser, signIn } = useActiveAuth();
  const { invalidateQueries } = useTanstackQuery();

  const [isShowQuestionSection, setIsShowQuestionSection] = useState(false);

  const {
    data: {
      bannerImageUrl,
      joinQuestion,
      useQuestion: isMemberShipUseQuestion,
      channelName,
      channelId,
      channelProfileImageUrl,
      autoApproval,
    } = {
      bannerImageUrl: '',
      joinQuestion: '',
      useQuestion: false,
      channelName: '',
      channelId: -1,
      channelProfileImageUrl: '',
    },
  } = useQuery<ChannelDetailDataType>({
    queryFn: () => getChannelDetailData(convertQueryToString(handle)),
    queryKey: ['channelDetailData', handle],
  });

  const [selectedMembership, setSelectedMembership] = useState<MembershipType>(
    membershipList[0],
  );

  const selectMembership = (membership: MembershipType) => {
    setSelectedMembership(membership);
  };

  const showQuestionSection = () => {
    setIsShowQuestionSection(true);
  };

  const joinMembershipMutation = useMutation({
    mutationFn: (data: {
      applyChannelId: number;
      membershipPlanId: number;
      answer?: string;
    }) => joinMembership(data),
    onSuccess: () => {
      invalidateQueries(['joinedMembership', 'channelMembershipList']);
      if (autoApproval === false)
        enqueueSnackbar(t('membership.joinSuccess'), {
          variant: 'success',
        });
      close();
    },
    onError: () => {
      enqueueSnackbar(t('membership.joinFail'), {
        variant: 'error',
      });
    },
  });

  const signUpOrShowQuestionStep = () => {
    if (isAnonymousUser) {
      signIn();
      return;
    }

    if (autoApproval === false && isMemberShipUseQuestion) {
      showQuestionSection();
      return;
    }

    joinMembershipMutation.mutate({
      applyChannelId: channelId,
      membershipPlanId: selectedMembership.id,
    });
  };

  const close = () => {
    closeDialog();
    setIsShowQuestionSection(false);
  };

  return (
    <CommonDialog.Dialog open={isOpen} onClose={close}>
      <CommonDialog.DialogTitle
        title={t('membership.title')}
        onClose={close}
        sx={{
          p: { xs: '16px 8px', sm: '30px 30px' },
        }}
      />
      <Box
        sx={{
          mb: '30px',
          px: {
            xs: '16px',
            sm: 0,
          },
        }}
      >
        {bannerImageUrl && (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              mb: '30px',
              aspectRatio: '4/1',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                background: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1,
              }}
            />
            <Image
              src={bannerImageUrl}
              alt={channelName}
              layout="fill"
              objectFit="cover"
            />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: {
                  xs: '16px',
                  sm: '30px',
                },
                transform: 'translate(0,-50%)',
                zIndex: 2,
                display: {
                  xs: 'flex',
                  sm: 'block',
                },
                gap: '8px',
              }}
            >
              <Avatar
                src={channelProfileImageUrl}
                sx={{
                  width: { xs: '40px', sm: '56px' },
                  height: { xs: '40px', sm: '56px' },
                  mb: {
                    xs: '0',
                    sm: '16px',
                  },
                }}
              />
              <Box>
                <ResponsiveTypography
                  xsVariant="h5Bold"
                  smVariant="h4Bold"
                  color="white"
                >
                  {channelName}
                </ResponsiveTypography>
                <ResponsiveTypography
                  xsVariant="body3"
                  smVariant="h6"
                  color="gray.200"
                >
                  {handle}
                </ResponsiveTypography>
              </Box>
            </Box>
          </Box>
        )}

        {isShowQuestionSection ? (
          <QuestionSection
            joinQuestion={joinQuestion}
            joinMembership={(answer: string) =>
              joinMembershipMutation.mutateAsync({
                applyChannelId: channelId,
                membershipPlanId: selectedMembership.id,
                answer,
              })
            }
            disabled={joinMembershipMutation.isPending}
          />
        ) : (
          <MembershipSelectSection
            membershipList={membershipList}
            selectMembership={selectMembership}
            selectedMembership={selectedMembership}
            signUpOrShowQuestionStep={signUpOrShowQuestionStep}
          />
        )}
      </Box>
    </CommonDialog.Dialog>
  );
};

export default MembershipSignUpDialog;
