import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import { Box, Button, MenuItem, MenuList, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import useCheckDevice from 'hooks/useCheckDevice';
import useCommonSx from 'hooks/useCommonSx';

import type { ChannelDetailDataType } from 'types/channel';
import type { MembershipType } from 'types/channelMembership';

const MembershipSelectSection = ({
  membershipList,
  selectMembership,
  selectedMembership,
  signUpOrShowQuestionStep,
}: {
  membershipList: MembershipType[];
  selectMembership: (membership: MembershipType) => void;
  selectedMembership: MembershipType;
  signUpOrShowQuestionStep: () => void;
}) => {
  const {
    query: { handle = '' },
  } = useRouter();
  const { t } = useTranslation('channel');

  const { oneLineSx } = useCommonSx();

  const {
    data: { channelId } = {
      channelId: -1,
    },
  } = useQuery<ChannelDetailDataType>({
    queryKey: ['channelDetailData', handle],
    enabled: Boolean(handle),
  });
  const { isMobile } = useCheckDevice();

  return (
    <>
      {isMobile ? (
        membershipList.map((membership) => (
          <Box
            key={membership.id}
            sx={{
              border: '1px solid',
              borderColor: 'border.normal',
              borderRadius: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'top',
                gap: '16px',
                padding: '16px',
              }}
            >
              <Typography variant="h5Bold">{membership.name}</Typography>
              <Button
                variant="solid"
                onClick={signUpOrShowQuestionStep}
                disabled={!channelId}
              >
                {t('signUp')}
              </Button>
            </Box>
            <Box
              sx={{
                backgroundColor: 'surface.normal',
                p: '20px 16px',
                borderRadius: '8px',
                m: '4px',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: '4px',
                }}
              >
                {membership.price > 0
                  ? `${membership.price}/Month`
                  : t('membership.free')}
              </Typography>
              <Typography
                variant="body4"
                color="text.tertiary"
                sx={{
                  mb: '24px',
                }}
              >
                {t('membership.desc')}
              </Typography>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {membership.description}
              </Typography>
            </Box>
          </Box>
        ))
      ) : (
        <Box
          sx={{
            gap: '40px',
            display: 'flex',
            mr: '30px',
          }}
        >
          <Box
            sx={{
              width: '260px',
              flexShrink: 0,
            }}
          >
            <MenuList>
              {membershipList.map((membership) => (
                <MenuItem
                  key={membership.id}
                  sx={{
                    backgroundColor:
                      selectedMembership.id === membership.id
                        ? 'surface.normal'
                        : 'transparent',
                  }}
                  onClick={() => selectMembership(membership)}
                >
                  <Box
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        ...oneLineSx,
                        display: 'block',
                      }}
                    >
                      {membership.name}
                    </Typography>
                    <Typography variant="h6" color="text.tertiary">
                      {membership.price > 0
                        ? `${membership.price}/Month`
                        : t('membership.free')}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Box>
          <Box>
            <Typography variant="subtitle2">{t('membership.free')}</Typography>
            <Typography
              variant="body3"
              sx={{ marginBottom: '16px', color: 'text.secondary' }}
            >
              {t('membership.desc')}
            </Typography>
            <Button
              variant="solid"
              size="large"
              sx={{
                mb: '40px',
                width: '164px',
              }}
              disabled={!channelId}
              onClick={signUpOrShowQuestionStep}
            >
              {t('signUp')}
            </Button>
            <Typography
              variant="body1"
              paragraph
              sx={{
                marginBottom: '16px',
                whiteSpace: 'pre-line',
              }}
            >
              {selectedMembership.description}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MembershipSelectSection;
